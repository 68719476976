<template>
  <fixed-header :isHideBack="$route.meta.hideBack"></fixed-header>
  <div class="home-wapper">
    <router-view v-slot="{ Component }" v-if="shouldKeepAlive">
      <!-- 'Order', -->
      <keep-alive
        :max="2"
        :include="[
          'Index',
          'News',
          'Topic',
          'product',
          'Course',
          'standard',
          'OpenSearch',
          'Personal',
          'Collection',
          'BnccCategory'
        ]"
      >
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <fixed-footer v-if="!$route.meta.hideFooter"></fixed-footer>
</template>

<script lang="ts">
import { defineComponent, ref, nextTick, provide } from "vue";
import fixedHeader from "@/components/common/Header.vue";
import fixedFooter from "@/components/common/Footer.vue";
export default defineComponent({
  name: "Home",
  components: {
    fixedHeader,
    fixedFooter,
  },
  setup() {
    const shouldKeepAlive = ref(true);
    function destoryKeepAlive() {
      return new Promise((resolve) => {
        shouldKeepAlive.value = false;
        nextTick(() => {
          shouldKeepAlive.value = true;
          resolve(true);
        });
      });
    }

    provide("destoryKeepAlive", destoryKeepAlive);
    return {
      shouldKeepAlive
    }
  },
});
</script>


<style lang="scss" scoped>
.home-wapper {
  padding-top: 55px;
}
</style>
